<template>
  <div>
    <payment-card
      :payment="payment"
      class="mb-4"
    />
    <v-row>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <payment-payer :payment="payment" />
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <payment-status :payment_id="payment.id" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PaymentCard from '@core/components/payment/PaymentCard'
import PaymentPayer from '@core/components/payment/PaymentPayer'
import PaymentStatus from '@core/components/payment/PaymentStatus'

export default {
  name: 'PaymentPanel',
  components: { PaymentStatus, PaymentPayer, PaymentCard },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
