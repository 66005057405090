<template>
  <v-card>
    <v-alert
      v-model="isAlertVisible"
      :color="error ? 'error' : 'success'"
      dismissible
      text
      transition="slide-y-transition"
    >
      {{ message }}
    </v-alert>
    <loader-dialog :is-dialog-visible="isDialogVisible" />
    <div class="d-flex align-center justify-space-between">
      <div>
        <v-card-title class="text-no-wrap">
          Paiement
        </v-card-title>
        <v-card-subtitle>Détails sur le paiement</v-card-subtitle>
      </div>

      <v-avatar
        color="primary"
        size="48"
        class="v-avatar-light-bg primary--text me-5"
      >
        <v-icon
          size="30"
          color="primary"
        >
          {{ icons.mdiCash }}
        </v-icon>
      </v-avatar>
    </div>

    <v-card-text>
      <v-list>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiInformation }}
            </v-icon>
            Reférence:
          </span>
          <span class="text--secondary font-weight-bold">{{ payment.reference }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiInformation }}
            </v-icon>
            Motif:
          </span>
          <span class="text--secondary font-weight-bold">{{ payment.pay_reason.label }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCash }}
            </v-icon>
            Montant:
          </span>
          <span class="text--secondary font-weight-bold">
            <v-chip
              small
              color="primary"
            >{{ formatAmount(payment.base_amount) }}</v-chip>
          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCashCheck }}
            </v-icon>
            Montant hors frais:
          </span>
          <span class="text--secondary font-weight-bold">
            <v-chip
              small
              color="secondary"
            >{{ formatAmount(payment.amount) }}</v-chip>

          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCashCheck }}
            </v-icon>
            Montant d'annulation:
          </span>
          <span class="text--secondary font-weight-bold">
            <v-chip
              small
              color="secondary"
            >{{ formatAmount(payment.fees_to_cancel) }}</v-chip>

          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCashCheck }}
            </v-icon>
            Note du trajet :
          </span>
          <span class="text--secondary font-weight-bold">
            <v-chip
              small
              color="info"
            >{{ payment.evaluate !== null ? payment.evaluate.note : null }} / 5</v-chip>

          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiInformationOutline }}
            </v-icon>
            ID transaction:
          </span>
          <span class="text--secondary font-weight-bold">{{ payment.transaction_id }}</span>
        </v-list-item>
        <v-list-item
          v-show="payment.reduct_code"
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCodeBraces }}
            </v-icon>
            Code de réduction:
          </span>
          <span class="text--secondary font-weight-bold">{{ payment.reduct_code ? payment.reduct_code.code : null }}</span>
        </v-list-item>
        <v-list-item
          v-show="payment.reduct_code"
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCodeBraces }}
            </v-icon>
            Valeur du code de réduction:
          </span>
          <span class="text--secondary font-weight-bold">
            <v-chip
              small
              color="info"
            >{{ payment.reduct_code ? payment.reduct_code.is_percent === true ?
              `${payment.reduct_code.value} %` : formatAmount(payment.reduct_code.value) : null }}
            </v-chip>
          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiInformationOutline }}
            </v-icon>
            Type:
          </span>
          <span class="text--secondary font-weight-bold">
            <v-chip
              v-if="payment.payment_type.code === 'CASHIN'"
              small
              color="primary"
            >
              <v-icon
                class="me-1"
                size="18"
              >
                {{ icons.mdiCashPlus }}
              </v-icon>
              {{ payment.payment_type.label }}
            </v-chip>
            <v-chip
              v-else
              small
              color="secondary"
            >
              <v-icon
                class="me-1"
                size="18"
              >
                {{ icons.mdiCashMinus }}
              </v-icon>
              {{ payment.payment_type.label }}
            </v-chip>
          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiContactlessPayment }}
            </v-icon>
            Opérateur:
          </span>
          <v-avatar>
            <v-img
              :src="payment.op_api.operator.logo_path"
              :title="payment.op_api.operator.label"
            ></v-img>
          </v-avatar>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiBusArticulatedEnd }}
            </v-icon>
            Départ:
          </span>
          <span class="text--secondary font-weight-bold">{{ payment.road ? payment.road.city.name : null }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiBusArticulatedEnd }}
            </v-icon>
            Arrivé:
          </span>
          <span class="text--secondary font-weight-bold">{{ payment.destination ? payment.destination.city.name : payment.road ? payment.road.destination.name : null }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiWallet }}
            </v-icon>
            Numéro de compte:
          </span>
          <span class="text--secondary font-weight-bold">
            {{ payment.svc_number }}
          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiClockStart }}
            </v-icon>
            Statut:
          </span>
          <span class="text--secondary font-weight-bold">
            <v-chip
              small
              :color="payment.status !== null ? payment.status.color: ''"
            >
              {{ payment.status !== null ? payment.status.label : payment.op_status }}
            </v-chip>
          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCalendar }}
            </v-icon>
            Date de création:
          </span>
          <span class="text--secondary font-weight-bold">{{ payment.created_at ? formattingDate(payment.created_at) : null }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCalendar }}
            </v-icon>
            Date de validation:
          </span>
          <span class="text--secondary font-weight-bold">{{ payment.validated_at ? formattingDate(payment.validated_at) : null }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCalendarEdit }}
            </v-icon>
            Date de mise à jour:
          </span>
          <span class="text--secondary font-weight-bold">{{ payment.updated_at ? formattingDate(payment.updated_at) : null }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCalendarMinus }}
            </v-icon>
            Date d'annulation:
          </span>
          <v-chip
            v-show="payment.cancelled_at !== null"
            color="error"
            small
          >
            <span class="text--secondary font-weight-bold">{{ payment.cancelled_at ? formattingDate(payment.cancelled_at) : null }}</span>
          </v-chip>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCalendarMinus }}
            </v-icon>
            Date de suppression:
          </span>
          <span class="text--secondary font-weight-bold">{{ payment.deleted_at ? formattingDate(payment.deleted_at) : null }}</span>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-tooltip
        left
        color="info"
      >
        <template #activator="{ attr, on }">
          <v-btn
            color="info"
            outlined
            icon
            x-large
            v-bind="attr"
            elevation="6"
            v-on="on"
            @click="verifyStatus(payment)"
          >
            <v-icon
              dark
            >
              {{ icons.mdiCashCheck }}
            </v-icon>
          </v-btn>
        </template>
        <span>Vérifier statut</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import {
  mdiBusArticulatedEnd,
  mdiBusArticulatedFront, mdiCalendar, mdiCalendarEdit, mdiCalendarMinus,
  mdiCash, mdiCashMarker,
  mdiClockStart,
  mdiImageText, mdiWallet, mdiCashPlus, mdiCashMinus, mdiContactlessPayment,
  mdiRoad, mdiSeatPassenger, mdiInformation, mdiCashCheck, mdiInformationOutline, mdiCodeBraces,
} from '@mdi/js'
import { formatDate } from '@core/date/dateFunctions'
import { intToCurrency, notify } from '@core/dynamicComponents/dynamicComponents'
import store from '@/store'

export default {
  name: 'PaymentCard',

  components: {
    LoaderDialog,
  },

  props: {
    payment: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isDialogVisible: false,
      isAlertVisible: false,
      error: false,
      message: null,
    }
  },

  methods: {
    formattingDate(date) {
      return formatDate(date)
    },

    verifyStatus(item) {
      this.isDialogVisible = true
      store.dispatch('payment/verifyPaymentStatus', item).then(response => {
        this.message = response.message
        this.isDialogVisible = false
        this.isAlertVisible = true
        notify(true, true, response.message)
        this.error = false
      }).catch(error => {
        console.log(error.response.data.message)
        this.message = error.response.data.message
        this.isDialogVisible = false
        this.isAlertVisible = true
        notify(true, true, error.response.data.message)
        this.error = true
      })
    },
  },

  setup() {
    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    return {
      formatAmount,
      icons: {
        mdiRoad,
        mdiImageText,
        mdiCash,
        mdiClockStart,
        mdiBusArticulatedFront,
        mdiBusArticulatedEnd,
        mdiSeatPassenger,
        mdiCashMarker,
        mdiCalendar,
        mdiCalendarMinus,
        mdiCalendarEdit,
        mdiInformation,
        mdiCashCheck,
        mdiInformationOutline,
        mdiWallet,
        mdiCashPlus,
        mdiCashMinus,
        mdiContactlessPayment,
        mdiCodeBraces,
      },
    }
  },
}
</script>

<style scoped>

</style>
