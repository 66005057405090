<template>
  <div>
    <custom-toolbar
      :title="'Détails du paiement'"
      @refresh="refreshObjet"
    />
    <v-row class="mt-1">
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <v-skeleton-loader
          v-show="isDialogVisible"
          boilerplate
          class="mx-auto"
          elevation="2"
          max-width="600"
          type="card-avatar, article, article, article, article, actions"
        ></v-skeleton-loader>
        <payment-panel
          v-show="!isDialogVisible"
          :payment="payment"
        />
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <v-skeleton-loader
          v-show="isDialogVisible"
          boilerplate
          class="mx-auto"
          elevation="2"
          max-width="600"
          type="card-avatar, article, article, article, article, actions"
        ></v-skeleton-loader>
        <withdraw-validator
          v-show="!isDialogVisible"
          v-if="$store.state.payment.payment.admin"
        />
        <road-panel
          v-show="!isDialogVisible"
          v-else
          :road="payment.road"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  getCurrentInstance,
  onMounted,
  ref,
  watch
} from '@vue/composition-api/dist/vue-composition-api'
import {
  mdiArrowLeftBoldCircleOutline,
  mdiCashMarker,
  mdiCashMultiple,
  mdiMapMarkerDistance,
  mdiRefresh,
} from '@mdi/js'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import PaymentPanel from '@core/components/payment/PaymentPanel'
import WithdrawValidator from '@core/components/payment/WithdrawValidator'
import store from '@/store'
import router from '@/router'
// eslint-disable-next-line import/extensions
import RoadPanel from '@/components/road/RoadPanel'
import CustomToolbar from "@core/components/toolbar/CustomToolbar";

export default {
  name: 'PaymentDetails',
  components: {
    CustomToolbar,
    WithdrawValidator, PaymentPanel, RoadPanel, LoaderDialog,
  },
  computed: {
    ...mapState('payment', ['payment']),
  },

  setup() {
    const isDialogVisible = ref(false)
    const userTab = ref(null)
    // eslint-disable-next-line no-shadow,no-use-before-define
    const route = getCurrentInstance().proxy.$route

    const getPayment = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        if (store.state.payment.payment === null || store.state.payment.payment.id !== id) {
          isDialogVisible.value = true
          store.dispatch('payment/getPaymentById', id).then(() => {
            isDialogVisible.value = false
          }).catch(error => {
            console.log(error)
          })
        }
      }
    }

    const refreshObjet = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        isDialogVisible.value = true
        store.dispatch('payment/getPaymentById', store.state.payment.payment.id).then(() => {
          isDialogVisible.value = false
        }).catch(error => {
          console.log(error)
        })
      }
    }

    onMounted(() => {
      getPayment()
    })

    watch(() => store.state.user.lang, () => {
      refreshObjet()
    })

    watch(() => store.state.payment.refreshPayCount, () => {
      isDialogVisible.value = true
      store.dispatch('payment/getPaymentById', store.state.payment.refreshPayId).then(() => {
        isDialogVisible.value = false
      }).catch(error => {
        console.log(error)
      })
    }, { deep: true })

    const prev = () => {
      router.back()
    }

    const tabs = [
      { icon: mdiMapMarkerDistance, title: 'Arrêts' },
      { icon: mdiCashMarker, title: 'Réservations' },
      { icon: mdiCashMultiple, title: 'Paiements' },
    ]

    return {
      isDialogVisible,
      getPayment,
      prev,
      refreshObjet,
      tabs,
      userTab,
      icons: {
        mdiRefresh,
        mdiArrowLeftBoldCircleOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
