<template>
  <div>
    <v-card>
      <div class="d-flex align-center justify-space-between">
        <div>
          <v-card-title class="text-no-wrap">
            Validateur du retrait
          </v-card-title>
          <v-card-subtitle>Détails sur le validateur</v-card-subtitle>
        </div>

        <v-avatar
          color="primary"
          size="48"
          class="v-avatar-light-bg primary--text me-5"
        >
          <v-icon
            size="30"
            color="primary"
          >
            {{ icons.mdiAccountSupervisorCircle }}
          </v-icon>
        </v-avatar>
      </div>

      <v-card-text>
        <v-list>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiAccountSupervisorCircle }}
              </v-icon>
              Nom:
            </span>
            <span
              class="text--secondary font-weight-bold"
              @click="openItem($store.state.payment.payment.admin)"
            >
              <v-chip pill>
                <v-avatar
                  :color=" $store.state.payment.payment.admin.profile_picture_path ? '' : 'primary'"
                  :class=" $store.state.payment.payment.admin.profile_picture_path ? '' : 'v-avatar-light-bg primary--text'"
                  size="32"
                  left
                >
                  <v-img
                    v-if=" $store.state.payment.payment.admin.profile_picture_path"
                    :src="`${ $store.state.payment.payment.admin.profile_picture_path}`"
                  ></v-img>
                  <span v-else>{{ $store.state.payment.payment.admin.name !== null && $store.state.payment.payment.admin.name !== undefined ?
                    $store.state.payment.payment.admin.name.slice(0,2).toUpperCase() : null }}</span>
                </v-avatar>
                {{ $store.state.payment.payment.admin.civility }} {{ $store.state.payment.payment.admin.name }}
              </v-chip>
            </span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiEmail }}
              </v-icon>
              Email:
            </span>
            <span class="text--secondary font-weight-bold">
              <v-chip
                color="primary"
                small
              >
                {{ $store.state.payment.payment.admin.email }}
              </v-chip>
            </span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiPhone }}
              </v-icon>
              Tel:
            </span>
            <span class="text--secondary font-weight-bold">
              <v-chip
                color="secondary"
                small
              >
                {{ $store.state.payment.payment.admin.phone_number }}
              </v-chip>
            </span>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiBadgeAccountAlert,
  mdiBusArticulatedEnd,
  mdiBusArticulatedFront, mdiCalendar, mdiCalendarEdit, mdiCalendarMinus,
  mdiCash, mdiCashMarker,
  mdiClockStart,
  mdiImageText, mdiMapMarkerDown, mdiMapMarkerUp,
  mdiRoad, mdiSeatPassenger, mdiPhone, mdiEmail, mdiAccountSupervisorCircle,
} from '@mdi/js'
import router from '@/router'

export default {
  name: 'WithdrawValidator',

  setup() {
    const openItem = item => {
      router.push({ name: 'showuser', params: { id: item.id } })
    }

    return {
      openItem,
      icons: {
        mdiRoad,
        mdiImageText,
        mdiAccountSupervisorCircle,
        mdiEmail,
        mdiCash,
        mdiClockStart,
        mdiBusArticulatedFront,
        mdiBusArticulatedEnd,
        mdiSeatPassenger,
        mdiCashMarker,
        mdiCalendar,
        mdiCalendarMinus,
        mdiCalendarEdit,
        mdiMapMarkerDown,
        mdiMapMarkerUp,
        mdiBadgeAccountAlert,
        mdiPhone,
      },
    }
  },
}
</script>

<style scoped>

</style>
