<template>
  <v-card>
    <div class="d-flex align-center justify-space-between">
      <div>
        <v-card-title class="text-no-wrap">
          {{ payment.payment_type.code === "CASHIN" ? "Passager" : "Bénéficiaire" }}
        </v-card-title>
        <v-card-subtitle>{{ payment.payment_type.code === "CASHIN" ? "Passager du voyage" : "Bénéficiaire" }}</v-card-subtitle>
      </div>

      <v-avatar
        color="primary"
        size="48"
        class="v-avatar-light-bg primary--text me-5"
      >
        <v-icon
          size="30"
          color="primary"
        >
          {{ payment.payment_type.code === "CASHIN" ? icons.mdiSeatPassenger : icons.mdiAccountCash }}
        </v-icon>
      </v-avatar>
    </div>

    <v-card-text>
      <v-list>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiAccount }}
            </v-icon>
            Nom:
          </span>
          <span
            class="text--secondary font-weight-bold"
            @click="openItem(payment.user)"
          >
            <v-chip pill>
              <v-avatar
                :color=" payment.user.profile_picture_path ? '' : 'primary'"
                :class=" payment.user.profile_picture_path ? '' : 'v-avatar-light-bg primary--text'"
                size="32"
                left
              >
                <v-img
                  v-if=" payment.user.profile_picture_path"
                  :src="`${ payment.user.profile_picture_path}`"
                ></v-img>
                <span v-else>{{ payment.user.name !== null && payment.user.name !== undefined ? payment.user.name.slice(0,2).toUpperCase() : null }}</span>
              </v-avatar>
              {{ payment.user.civility }} {{ payment.user.name }}
            </v-chip>
          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiPhone }}
            </v-icon>
            Tel:
          </span>
          <span class="text--secondary font-weight-bold">{{ payment.user.phone_number }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiEmail }}
            </v-icon>
            Email:
          </span>
          <span class="text--secondary font-weight-bold">{{ payment.user.email }}</span>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAccount,
  mdiCarArrowLeft,
  mdiCarDoor,
  mdiCarEstate,
  mdiCarSettings,
  mdiEmail, mdiFileDocument, mdiSeatPassenger,
  mdiPhone, mdiAccountCash,
} from '@mdi/js'
import router from '@/router'

export default {
  name: 'PaymentPayer',

  props: {
    payment: {
      type: Object,
      required: true,
    },
  },

  methods: {
    openItem(item) {
      router.push({ name: 'showuser', params: { id: item.id } })
    },
  },
  setup() {
    return {
      icons: {
        mdiCarArrowLeft,
        mdiAccount,
        mdiPhone,
        mdiEmail,
        mdiCarEstate,
        mdiCarDoor,
        mdiCarSettings,
        mdiFileDocument,
        mdiSeatPassenger,
        mdiAccountCash,
      },
    }
  },
}
</script>

<style scoped>

</style>
